export function groupBy<T, S extends string | number | symbol>(
  collection: readonly T[],
  toGroup: (item: T) => S,
): Partial<Record<S, T[]>> {
  return collection.reduce<Partial<Record<S, T[] | undefined>>>((acc, item) => {
    const group = toGroup(item);
    if (acc[group]) return { ...acc, [group]: [...acc[group], item] };
    return { ...acc, [group]: [item] };
  }, {});
}
