import { Interpolation } from '@emotion/react';
import { CustomTheme } from './theme';

export const globalStyles = (
  theme: CustomTheme,
): Interpolation<CustomTheme> => `
    :not(pre) > code[class*='language-'] {
    padding: 0.1em 0.3em;
  }

  .quote-danger .MuiPaper-root {
    border-left: 4px solid ${theme.palette.red['60']};
  }

  .quote-info .MuiPaper-root {
    border-left: 4px solid ${theme.palette.blue['60']};
  }

  .MuiTypography-root svg {
    line-height: normal;
    letter-spacing: normal;
  }

  code {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  }
`;
