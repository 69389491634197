const customPalette = {
  black: '#222226',
  greyDark: '#5B5C67',
  greyIntense: '#A9A9B2',
  greyMed: '#DFDFE2',
  greyLight: '#EFEFF1',
  white: '#FAFAFA',
  whitePure: '#FFFFFF',
  yellow: {
    100: '#CC8F00',
    50: '#FED400',
    40: '#FFE666',
    20: '#FFF8D6',
  },
  red: {
    100: '#A30000',
    80: '#CC1414',
    60: '#EB4747',
    40: '#F28787',
    20: '#FFD6D6',
  },
  green: {
    100: '#007012',
    80: '#09A522',
    60: '#7CDE8D',
    40: '#A1EDAD',
    20: '#CCFFD4',
  },
  blue: {
    100: '#003585',
    80: '#0056D6',
    60: '#7AAFFF',
    40: '#B8D4FF',
    20: '#E0EDFF',
  },
  purple: {
    100: '#5E007A',
    80: '#8E1BB1',
    60: '#D988F2',
    40: '#EBBFF8',
    20: '#F6E3FC',
  },
};

export const palette = {
  ...customPalette,
  primary: {
    light: customPalette.yellow['20'],
    main: customPalette.yellow['50'],
    dark: customPalette.yellow['100'],
    contrastText: customPalette.black,
  },
  secondary: {
    light: customPalette.greyMed,
    main: customPalette.black,
    dark: customPalette.greyIntense,
    contrastText: customPalette.white,
  },
};
