import styled from '@emotion/styled';
import { TableContainer } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { memo, useMemo } from 'react';
import { InlineCode } from './InlineCode';
import { StyledLink } from './StyledLink';

const components = {
  p: (() => {
    const P = (props) => (
      <Typography {...props} component="p" variant="body1" paragraph />
    );
    return memo(P);
  })(),
  h1: (() => {
    const H1 = (props) => (
      <Typography {...props} component="h1" variant="h1" paragraph />
    );
    return memo(H1);
  })(),
  h2: (() => {
    const H2 = (props) => (
      <Typography {...props} component="h2" variant="h2" paragraph />
    );
    return memo(H2);
  })(),
  h3: (() => {
    const H3 = (props) => (
      <Typography {...props} component="h3" variant="h3" paragraph />
    );
    return memo(H3);
  })(),
  h4: (() => {
    const H4 = (props) => (
      <Typography {...props} component="h4" variant="h4" paragraph />
    );
    return memo(H4);
  })(),
  h5: (() => {
    const H5 = (props) => (
      <Typography {...props} component="h5" variant="h5" paragraph />
    );
    return memo(H5);
  })(),
  h6: (() => {
    const H6 = (props) => (
      <Typography {...props} component="h6" variant="h6" paragraph />
    );
    return memo(H6);
  })(),
  blockquote: (() => {
    const Blockquote = styled(Paper)`
      border-left: 4px solid ${({ theme }) => theme.palette.greyIntense};
      margin: ${({ theme }) => theme.spacing(2)}
        ${({ theme }) => theme.spacing(1)};
      padding: ${({ theme }) => theme.spacing(2)};
    `;
    return memo(Blockquote);
  })(),
  ul: (() => {
    const Ul = (props) => <Typography {...props} component="ul" paragraph />;
    return memo(Ul);
  })(),
  ol: (() => {
    const Ol = (props) => <Typography {...props} component="ol" paragraph />;
    return memo(Ol);
  })(),
  li: (() => {
    const Li = (props) => <Typography {...props} component="li" />;
    return memo(Li);
  })(),
  table: (() => {
    const Table = styled(({ className, ...props }) => (
      <TableContainer component={Paper} className={className}>
        <MuiTable {...props} />
      </TableContainer>
    ))`
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    `;
    return memo(Table);
  })(),
  tr: (() => {
    const Tr = (props) => <TableRow {...props} />;
    return memo(Tr);
  })(),
  td: (() => {
    // eslint-disable-next-line react/prop-types
    const Td = ({ align, ...props }) => (
      <TableCell align={align || undefined} {...props} />
    );
    return memo(Td);
  })(),
  tbody: (() => {
    const TBody = (props) => <TableBody {...props} />;
    return memo(TBody);
  })(),
  th: (() => {
    // eslint-disable-next-line react/prop-types
    const Th = ({ align, ...props }) => (
      <TableCell align={align || undefined} {...props} />
    );
    return memo(Th);
  })(),
  thead: (() => {
    const THead = (props) => <TableHead {...props} />;
    return memo(THead);
  })(),
  hr: Divider,
  a: (() => {
    const A = (props) => <StyledLink {...props} />;
    return memo(A);
  })(),
  input: (() => {
    const Input = (props) => {
      // eslint-disable-next-line react/prop-types
      const { type } = props;
      if (type === 'checkbox') {
        return <Checkbox {...props} disabled={false} readOnly />;
      }
      return <input {...props} />;
    };
    return memo(Input);
  })(),
  wrapper: (() => {
    // eslint-disable-next-line react/prop-types
    const Wrapper = ({ children }) => (
      <div className="markdown-body">{children}</div>
    );
    return memo(Wrapper);
  })(),
};

export default components;
