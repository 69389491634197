import { Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { Box, Container, CssBaseline, Toolbar } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { PageProps } from 'gatsby';
import React, { FC, Fragment } from 'react';
import { useSiteTitle } from '../../hooks/siteTitleHook';
import { globalStyles } from '../../styles/globalStyles';
import { createCustomTheme } from '../../styles/theme';
import { AppBar } from './AppBar';
import { Drawer } from './Drawer';
import { drawerWidth } from './StyledDrawer';

interface PageContext {
  specId?: string;
  specUrl?: string;
  frontmatter: {
    title: string;
  };
}

export const ColorModeContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});

// eslint-disable-next-line @typescript-eslint/ban-types
const Layout: FC<PageProps<{}, PageContext>> = ({ children, pageContext }) => {
  const { siteTitle } = useSiteTitle();

  const [mode, setMode] = React.useState<'light' | 'dark'>('light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem('preferred-theme', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const theme = React.useMemo(() => createCustomTheme(mode), [mode]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    const theme = localStorage.getItem('preferred-theme');
    if (theme) {
      const themePreference = localStorage.getItem('preferred-theme');
      if (themePreference === 'light' || themePreference === 'dark')
        setMode(themePreference);
    } else {
      localStorage.setItem('preferred-theme', mode);
    }
  }, []);

  return (
    <Fragment>
      <ColorModeContext.Provider value={colorMode}>
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <CssBaseline />
            <Global styles={globalStyles} />
            <Box display="flex">
              <AppBar
                title={pageContext.frontmatter?.title || siteTitle}
                setDrawerOpen={setDrawerOpen}
                currentSpec={
                  pageContext.specId ? { fileUrl: pageContext.specUrl } : null
                }
              />
              <Drawer
                currentSpecId={pageContext.specId}
                open={drawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
              <Box width={{ sm: `calc(100vw - ${drawerWidth}px)` }}>
                <Toolbar />
                <Box py={4}>
                  <Container component="main" maxWidth="lg">
                    {children}
                  </Container>
                </Box>
              </Box>
            </Box>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </ColorModeContext.Provider>
    </Fragment>
  );
};

export default Layout;
