import { graphql, useStaticQuery } from 'gatsby';

export const useSiteTitle = () => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return { siteTitle };
};
