import { Link as MuiLink } from '@mui/material';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

export const StyledLink = (props) => {
  const { to } = props;
  return to ? (
    <MuiLink
      component={GatsbyLink}
      {...props}
      color="inherit"
      underline="always"
    />
  ) : (
    <MuiLink {...props} color="inherit" underline="always" />
  );
};
StyledLink.defaultProps = {
  to: undefined,
};
