import { MDXProvider } from '@mdx-js/react';
import { SnackbarProvider } from 'notistack';
import React, { FunctionComponent } from 'react';
import components from './components/MdxComponents';

// eslint-disable-next-line react/prop-types
export const wrapRootElement: FunctionComponent<any> = ({ element }) => (
  <React.Fragment>
    <SnackbarProvider maxSnack={1}>
      <MDXProvider components={components}>{element}</MDXProvider>
    </SnackbarProvider>
  </React.Fragment>
);
