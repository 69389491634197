import { Box } from '@mui/material';
import React, { FC } from 'react';
import { DrawerHeader } from './DrawerHeader';
import { DrawerLink, DrawerLinkConfig } from './DrawerLink';
import { DrawerSection, DrawerSectionConfig } from './DrawerSection';
import { ScrollingList } from './ScrollingList';
import { StyledDrawer } from './StyledDrawer';

export type DrawerItem =
  | DrawerLinkConfig
  | DrawerSectionConfig
  | DrawerSpacerConfig;

interface DrawerSpacerConfig {
  type: 'spacer';
  key: number;
}

export interface DrawerPage {
  items: DrawerItem[];
}

interface Props {
  config: DrawerPage;
}

export const DesktopDrawer: FC<Props> = ({ config }) => (
  <StyledDrawer variant="permanent">
    <DrawerHeader />
    <ScrollingList>
      {config.items.map((item) =>
        item.type === 'link' ? (
          <DrawerLink key={item.to} {...item} />
        ) : item.type === 'section' ? (
          <DrawerSection key={item.key} {...item} />
        ) : (
          <Box key={item.key} my={4} />
        ),
      )}
    </ScrollingList>
  </StyledDrawer>
);
