import styled from '@emotion/styled';
import { Drawer as MuiDrawer } from '@mui/material';

export const drawerWidth = 280;

interface StyledDrawerProps {
  isMobile?: boolean;
}

export const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StyledDrawerProps>`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
  }

  .MuiDrawer-paper {
    box-sizing: border-box;
    width: ${drawerWidth}px;
    max-height: 100vh;
  }
`;
