import styled from '@emotion/styled';
import { Brightness4, Brightness7, Download, Menu } from '@mui/icons-material';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { ColorModeContext } from './Layout';
import { drawerWidth } from './StyledDrawer';

const StyledAppBar = styled(MuiAppBar)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: calc(100% - ${drawerWidth}px);
    margin-left: ${drawerWidth}px;
  }
`;

const MobileButton = styled(IconButton)`
  margin-right: 2px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: none;
  }
`;

type AppBarProps = {
  title: string;
  setDrawerOpen: (value: boolean) => void;
  currentSpec?: {
    fileUrl: string;
  };
};

export const AppBar = ({ title, setDrawerOpen, currentSpec }: AppBarProps) => {
  const colorMode = React.useContext(ColorModeContext);
  const theme = useTheme();

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <MobileButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setDrawerOpen(true)}
          size="large"
        >
          <Menu />
        </MobileButton>
        <Typography variant="h6" noWrap component="div" width={1}>
          {title}
        </Typography>
        {currentSpec && (
          <IconButton
            component="a"
            color="inherit"
            href={currentSpec.fileUrl}
            download
          >
            <Download />
          </IconButton>
        )}
        <IconButton onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
};
