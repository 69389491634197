import { Box } from '@mui/material';
import React, { FC } from 'react';
import { DrawerPage } from './DesktopDrawer';
import { DrawerHeader } from './DrawerHeader';
import { DrawerLink } from './DrawerLink';
import { DrawerSection } from './DrawerSection';
import { ScrollingList } from './ScrollingList';
import { StyledDrawer } from './StyledDrawer';

interface Props {
  open: boolean;
  onClose: () => void;
  config: DrawerPage;
}

export const MobileDrawer: FC<Props> = ({ open, onClose, config }) => {
  const container =
    // eslint-disable-next-line no-undef
    typeof window !== 'undefined' ? () => window.document.body : undefined;

  return (
    <StyledDrawer
      container={container}
      variant="temporary"
      open={open}
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      isMobile
    >
      <DrawerHeader />
      <ScrollingList>
        {config.items.map((item) =>
          item.type === 'link' ? (
            <DrawerLink key={item.to} {...item} />
          ) : item.type === 'section' ? (
            <DrawerSection key={item.key} {...item} />
          ) : (
            <Box key={item.key} my={4} />
          ),
        )}
      </ScrollingList>
    </StyledDrawer>
  );
};
