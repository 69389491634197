import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import React, { FC } from 'react';

const getVariant = (palette, colorKey) =>
  palette.mode === 'light' ? palette[colorKey]['20'] : palette[colorKey]['100'];

const GreenChip = styled(Chip)`
  background: ${({ theme }) => getVariant(theme.palette, 'green')};
`;
export const Get: FC = () => (
  <GreenChip variant="outlined" size="small" label="GET" />
);

const BlueChip = styled(Chip)`
  background: ${({ theme }) => getVariant(theme.palette, 'blue')};
`;
export const Put: FC = () => (
  <BlueChip variant="outlined" size="small" label="PUT" />
);

const YellowChip = styled(Chip)`
  background: ${({ theme }) => getVariant(theme.palette, 'purple')};
`;
export const Post: FC = () => (
  <YellowChip variant="outlined" size="small" label="POST" />
);

const RedChip = styled(Chip)`
  background: ${({ theme }) => getVariant(theme.palette, 'red')};
`;
export const Delete: FC = () => (
  <RedChip variant="outlined" size="small" label="DELETE" />
);

const methodChips = {
  get: Get,
  put: Put,
  post: Post,
  delete: Delete,
};

export default methodChips;
