import { Box, List, ListSubheader } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { DrawerLink, DrawerLinkConfig } from './DrawerLink';

export interface DrawerSectionConfig {
  type: 'section';
  key: string;
  label: string;
  badge?: ReactElement;
  links: DrawerLinkConfig[];
}

export const DrawerSection: FC<DrawerSectionConfig> = ({
  label,
  badge,
  links,
}) => (
  <List
    subheader={
      <ListSubheader
        disableSticky
        sx={(theme) => ({
          lineHeight: 'normal',
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(1),
          borderTop: `1px solid ${theme.palette.divider}`,
        })}
      >
        <span>{label}</span>
        <Box component="span" ml={1}>
          {badge}
        </Box>
      </ListSubheader>
    }
  >
    {links.map((item) => (
      <DrawerLink key={item.to} {...item} />
    ))}
  </List>
);
