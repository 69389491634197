import { graphql, useStaticQuery } from 'gatsby';

interface QueryReturn {
  allApiSpec: { nodes: GatsbyTypes.ApiSpec[] };
}

export const useSpecPages = (): { allApiSpec: GatsbyTypes.ApiSpec[] } => {
  const {
    allApiSpec: { nodes: allApiSpec },
  } = useStaticQuery<QueryReturn>(graphql`
    query {
      allApiSpec {
        nodes {
          specId
          pagePath
          defaultPage
          info {
            title
          }
          tags {
            name
            x_badge
          }
          childrenApiMarkdownDoc {
            pagePath
            fileAbsolutePath
            parent {
              ... on Mdx {
                frontmatter {
                  title
                  group
                }
              }
            }
          }

          childrenApiOperation {
            pagePath
            method
            summary
            tags
            apiVersions
          }
        }
      }
    }
  `);

  return { allApiSpec };
};
