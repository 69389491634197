import styled from '@emotion/styled';
import { ArrowBack, CloudDownload, Dashboard } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useSpecPages } from '../../hooks/specPagesHook';
import { ApiMarkdownDoc } from '../../types';
import { groupBy } from '../../utils/groupBy';
import { capitalizeFirstLetter } from '../../utils/string';
import methodChips from '../OperationMethodChips';
import { DesktopDrawer, DrawerItem, DrawerPage } from './DesktopDrawer';
import { DrawerLinkConfig } from './DrawerLink';
import { DrawerSectionConfig } from './DrawerSection';
import { MobileDrawer } from './MobileDrawer';
import { drawerWidth } from './StyledDrawer';

const DrawerBox = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const mdDocToLink = ({
  pagePath,
  parent: {
    frontmatter: { title },
  },
}: ApiMarkdownDoc): DrawerLinkConfig => ({
  type: 'link',
  to: pagePath,
  label: title,
  dense: true,
});

const byFileName = (a: ApiMarkdownDoc, b: ApiMarkdownDoc) =>
  a.fileAbsolutePath < b.fileAbsolutePath
    ? -1
    : a.fileAbsolutePath === b.fileAbsolutePath
      ? 0
      : 1;

const markdownDocItems = (
  apiMarkdownDocs: readonly ApiMarkdownDoc[],
): DrawerItem[] => {
  const { grouped, ungrouped } = groupBy(apiMarkdownDocs, (doc) =>
    doc.parent.frontmatter.group ? 'grouped' : 'ungrouped',
  );
  const [sortedGrouped, sortedUngrouped] = [grouped, ungrouped].map((group) =>
    (group || []).sort(byFileName),
  );
  return [
    ...sortedUngrouped.map(mdDocToLink),
    ...Object.entries(
      groupBy(sortedGrouped, (doc) => doc.parent.frontmatter.group),
    ).map<DrawerSectionConfig>(([group, docs]) => ({
      type: 'section',
      key: `mdDoc-${group}`,
      label: group,
      links: docs.map(mdDocToLink),
    })),
  ];
};

const apiLinks = ({
  pagePath,
  info: { title },
  tags,
  childrenApiMarkdownDoc,
  childrenApiOperation,
}: GatsbyTypes.ApiSpec): DrawerItem[] => {
  const badges = tags.reduce((b, t) => ({ ...b, [t.name]: t.x_badge }), {});

  return [
    {
      type: 'link',
      leftIcon: ArrowBack,
      to: '/',
      label: 'Home',
    },
    ...markdownDocItems(childrenApiMarkdownDoc as ApiMarkdownDoc[]),
    { type: 'spacer', key: 0 },
    {
      type: 'link',
      leftIcon: Dashboard,
      to: pagePath,
      label: title,
    },
    ...Object.entries(
      groupBy(
        childrenApiOperation,
        (operation) => operation.tags?.[0] || 'none',
      ),
    ).map<DrawerSectionConfig>(([groupName, operations]) => {
      return {
        type: 'section',
        key: `apiOperation-${groupName}`,
        badge: badges[groupName] ? (
          <Chip
            variant="outlined"
            color="warning"
            size="small"
            sx={{
              fontWeight: 400,
              fontSize: '0.6rem',
              height: '16px',
            }}
            label={badges[groupName]}
          />
        ) : null,
        label: capitalizeFirstLetter(groupName),
        links: operations.map(
          ({
            pagePath: operationPath,
            method,
            summary,
            apiVersions,
          }): DrawerLinkConfig => {
            const MethodChip = methodChips[method];
            return {
              type: 'link',
              rightIcons: [
                ...(apiVersions
                  ? apiVersions.map((v) => (
                      <Chip
                        variant="outlined"
                        color="warning"
                        size="small"
                        sx={{
                          mr: 0.5,
                          fontSize: '0.6rem',
                          height: '16px',
                        }}
                        label={v}
                        key={v}
                      />
                    ))
                  : []),
                <MethodChip key="method" />,
              ],
              to: operationPath,
              label: summary,
              dense: true,
            };
          },
        ),
      };
    }),
    { type: 'spacer', key: 1 },
  ];
};

const useDrawerConfig = (
  currentSpecId: string | undefined,
  allApiSpec: GatsbyTypes.ApiSpec[],
): DrawerPage => {
  return useMemo(() => {
    const configs: { [key: string]: DrawerPage } = {
      main: {
        items: allApiSpec.map(
          ({ pagePath, defaultPage, info: { title } }): DrawerLinkConfig => ({
            type: 'link',
            leftIcon: CloudDownload,
            to: `${pagePath}${defaultPage ? `/${defaultPage}` : ''}`,
            label: title,
          }),
        ),
      },
      ...allApiSpec.reduce(
        (acc, apiSpec) => ({
          ...acc,
          [apiSpec.specId]: { items: apiLinks(apiSpec) },
        }),
        {},
      ),
    };
    return currentSpecId ? configs[currentSpecId] : configs.main;
  }, [allApiSpec, currentSpecId]);
};

interface Props {
  currentSpecId?: string;
  open: boolean;
  setDrawerOpen: (value: boolean) => void;
}

export const Drawer: FC<Props> = ({ currentSpecId, open, setDrawerOpen }) => {
  const { allApiSpec } = useSpecPages();

  const config = useDrawerConfig(currentSpecId, allApiSpec);

  return (
    <DrawerBox component="nav" aria-label="documentation links">
      <MobileDrawer
        open={open}
        onClose={() => setDrawerOpen(false)}
        config={config}
      />
      <DesktopDrawer config={config} />
    </DrawerBox>
  );
};
