import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link, withPrefix } from 'gatsby';
import React, { FC, FunctionComponent, ReactElement } from 'react';

export interface DrawerLinkConfig {
  type: 'link';
  leftIcon?: FunctionComponent;
  to: string;
  label: string;
  rightIcons?: ReactElement[];
  dense?: boolean;
}

const withoutPrefix = (pathname: string) =>
  pathname.replace(withPrefix(''), '/');

export const DrawerLink: FC<DrawerLinkConfig> = ({
  leftIcon: LeftIcon,
  to,
  label,
  rightIcons,
  dense,
}) => (
  <ListItemButton
    key={to}
    component={Link}
    to={to}
    selected={
      typeof window !== 'undefined' &&
      to === withoutPrefix(window.location.pathname)
    }
    dense={dense}
    disableRipple
  >
    {LeftIcon && (
      <ListItemIcon>
        <LeftIcon />
      </ListItemIcon>
    )}
    <ListItemText
      primary={label}
      primaryTypographyProps={{ noWrap: true }}
      sx={{ pr: rightIcons ? 0.5 : 0 }}
    />
    {rightIcons}
  </ListItemButton>
);
