import { Divider, Toolbar, useTheme } from '@mui/material';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

export const DrawerHeader: FC = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Toolbar>
        <Link to="/">
          {theme.palette.mode === 'dark' ? (
            <StaticImage
              src="../../images/logo-line-dark.png"
              height={48}
              placeholder="blurred"
              alt="logo"
            />
          ) : (
            <StaticImage
              src="../../images/logo-line.png"
              height={48}
              placeholder="blurred"
              alt="logo"
            />
          )}
        </Link>
      </Toolbar>
      <Divider />
    </React.Fragment>
  );
};
